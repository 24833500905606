<template>
    <div
        v-pendo-loading="loadingConfig"
        class="guide-list-container"
        data-cy="guide-list-container">
        <app-card-overlay
            :is-editing="isEditingGuidesList"
            :confirmation-modal-active="!!activeModal">
            <template #card>
                <pendo-card title="Content">
                    <template #headerRight>
                        <div
                            v-if="!isEditingGuidesList && canEditRCContent"
                            v-pendo-tooltip="!subHasGuides && noGuidesTooltipContent"
                            data-cy="edit-module-list-button">
                            <pendo-button
                                v-if="shouldShowEditButton && !loading && localModuleGuides.length < 1"
                                :disabled="!subHasGuides"
                                theme="app"
                                type="link"
                                label="Add Guides"
                                size="mini"
                                prefix-icon="plus"
                                @click="showGuidesMultiselect" />
                            <pendo-button
                                v-if="shouldShowEditButton && localModuleGuides.length > 0"
                                theme="app"
                                type="link"
                                label="Edit"
                                size="mini"
                                prefix-icon="edit-2"
                                @click="showGuidesMultiselect" />
                        </div>
                        <pendo-button
                            v-if="isEditingGuidesList"
                            theme="app"
                            type="tertiary"
                            label="Cancel"
                            size="mini"
                            @click="onModalCancel" />
                        <pendo-button
                            v-if="isEditingGuidesList"
                            :disabled="!userHasChanges"
                            theme="app"
                            type="primary"
                            label="Save"
                            size="mini"
                            @click="toggleModal(MODALS.CONFIRM_SAVE)" />
                    </template>
                    <template #body>
                        <div
                            v-if="isEditingGuidesList"
                            :class="{ 'bottom-line': !localModuleGuides.length }"
                            class="select-guide-container"
                            data-cy="module-guide-chooser">
                            <pendo-multiselect
                                :value="localModuleGuides"
                                :options="guideOptions"
                                :min-trigger-width="246"
                                :max-trigger-width="246"
                                :disabled="loading"
                                multiple
                                searchable
                                placeholder="Select Existing Guides"
                                :close-on-select="false"
                                :clear-on-select="false"
                                :show-selected-values="false"
                                :block-keys="['Delete']"
                                preserve-search
                                theme="light"
                                label-key="title"
                                @input="handleModuleGuidesChange">
                                <template #option="{ option }">
                                    <pendo-checkbox-option :option="option">
                                        <pendo-guide-status-option :option="option" />
                                    </pendo-checkbox-option>
                                </template>
                            </pendo-multiselect>
                        </div>
                        <guide-list-content-table
                            :module="module"
                            :guides="localModuleGuides"
                            :is-editing="isEditingGuidesList"
                            @set="handleModuleGuidesChange"
                            @order="handleGuideOrderChange"
                            @nameChange="handleModuleGuidesNameChange"
                            @userHasChanges="userHasChanges = true" />
                        <div
                            v-if="!localModuleGuides.length && !isFetching"
                            class="no-guides-msg-wrapper">
                            <div
                                v-if="!isModuleLoading"
                                class="no-guides-msg">
                                <div class="header">
                                    No guides have been added to this module.
                                </div>
                                <div
                                    v-if="canEditRCContent"
                                    class="body">
                                    Add an existing guide by editing the content card.
                                </div>
                            </div>
                        </div>
                    </template>
                </pendo-card>
            </template>
        </app-card-overlay>

        <pendo-modal
            :visible="activeModal === MODALS.CONFIRM_SAVE"
            :confirm-button-config="{ type: 'primary', theme: 'app', label: 'Save Module' }"
            :message="confirmSaveText"
            type="confirmation"
            title="Save Module?"
            width="400px"
            @confirm="onModalConfirm(MODALS.CONFIRM_SAVE)"
            @cancel="toggleModal" />
        <pendo-modal
            :visible="activeModal === MODALS.CONFIRM_CANCEL"
            :confirm-button-config="{ type: 'primary', theme: 'app', label: 'Don\'t Save Changes' }"
            :cancel-button-config="{ type: 'secondary', theme: 'app', label: 'Continue Editing' }"
            title="You have unsaved changes!"
            type="confirmation"
            message="Any guides you’ve added or removed will not be saved to your Resource Center. Are you sure you don’t want to save changes?"
            width="400px"
            @confirm="onModalConfirm(MODALS.DO_NOT_SAVE)"
            @cancel="toggleModal" />
    </div>
</template>

<script>
import {
    PendoButton,
    PendoCard,
    PendoMultiselect,
    PendoCheckboxOption,
    PendoGuideStatusOption,
    PendoModal,
    PendoNotification,
    PendoTooltip,
    PendoLoading
} from '@pendo/components';
import AppCardOverlay from '@/stateless-components/common/AppCardOverlay';
import { getValidGuidesForRC } from '@/stateless-components/utils/resource-center';
import { BuildingBlockLayouts } from '@pendo/services/BuildingBlocks';
import { launchMethodToString, findModuleGuideListBuildingBlock, buildSegmentFromGuide } from '@/utils/guides';
import { parsedBuildingBlockString } from '@/utils/resource-center';
import { mapGetters, mapActions, mapState } from 'vuex';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import find from 'lodash/find';
import has from 'lodash/has';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import GuideListContentTable from './GuideListContentTable.vue';

const { moduleIds, ONBOARDING_MODULE_SUBTITLE } = BuildingBlockLayouts;
const MODALS = Object.freeze({
    CONFIRM_SAVE: 'confirmSave',
    CONFIRM_CANCEL: 'confirmCancel',
    DO_NOT_SAVE: 'doNotSave'
});

export default {
    name: 'GuideList',
    components: {
        PendoButton,
        PendoCard,
        PendoMultiselect,
        PendoCheckboxOption,
        PendoGuideStatusOption,
        PendoModal,
        GuideListContentTable,
        AppCardOverlay
    },
    directives: {
        PendoTooltip,
        PendoLoading
    },
    props: {
        module: {
            type: Object,
            required: true
        },
        parentLoading: {
            type: Boolean,
            deafault: false
        },
        canEditRCContent: {
            type: Boolean,
            required: true
        }
    },
    data () {
        return {
            localModuleGuides: [],
            previousGuides: [],
            loading: false,
            isEditingGuidesList: false,
            activeModal: null,
            MODALS,
            userHasChanges: false,
            noGuidesTooltipContent: 'No existing Guides to select from.'
        };
    },
    computed: {
        ...mapState({
            route: (state) => state.route,
            errorOnSave: (state) => state.resourceCenter.error,
            isFetching: (state) => state.resourceCenter.isFetching
        }),
        ...mapGetters({
            hasProductLineMetadataEnabled: 'subscriptions/hasProductLineMetadataEnabled',
            activeProductLines: 'subscriptions/activeProductLines',
            guidesList: 'guides/list',
            getChildGuides: 'resourceCenter/getChildGuides',
            segmentsList: 'filters/segmentsList',
            segmentsListByGroup: 'filters/segmentsListByGroup',
            activeResourceCenter: 'resourceCenter/getActiveResourceCenter',
            isActiveRCPublic: 'resourceCenter/isActiveRCPublic',
            hasActiveBeenPublishedBefore: 'resourceCenter/hasActiveBeenPublishedBefore'
        }),
        isPublic () {
            if (!this.module) return false;

            return this.isActiveRCPublic;
        },
        loadingConfig () {
            return {
                loading: this.isModuleLoading,
                type: 'feather',
                spinnerProps: {
                    background: 'rgba(255, 255, 255, 0.5)'
                }
            };
        },
        isModuleLoading () {
            return this.loading || this.isFetching || this.parentLoading;
        },
        productLines () {
            return {
                hasNps: this.activeProductLines.nps.purchased,
                hasValidate: this.hasProductLineMetadataEnabled('voc', 'ideas')
            };
        },
        validGuides () {
            return getValidGuidesForRC(this.guidesList, this.module.appId, this.productLines);
        },
        guideOptions () {
            const guideType = this.isAnnouncementsModule ? 'announcements' : 'guidesList';
            const mappedGuides = this.validGuides[guideType].map((guide) => this.formatDropdownGuide(guide));

            return orderBy(mappedGuides, [(guide) => guide.title.toLowerCase()], 'name');
        },
        subHasGuides () {
            return this.guideOptions.length > 0;
        },
        localModuleHasBuildingBlocks () {
            return has(this.module, 'steps[0].buildingBlocks');
        },
        localTemplateChildren () {
            if (!this.module || !this.localModuleHasBuildingBlocks) return [];

            const buildingBlocks = parsedBuildingBlockString(this.module.steps[0].buildingBlocks);
            const templateBlock = findModuleGuideListBuildingBlock(buildingBlocks);

            if (!templateBlock) return [];

            return templateBlock.templateChildren;
        },
        showPublishedGuideMessage () {
            const { id } = this.module;
            const modules = get(this, 'activeResourceCenter.public.modules', []);

            return this.hasActiveBeenPublishedBefore && !!modules.find((module) => module.id === id);
        },
        confirmSaveText () {
            return this.showPublishedGuideMessage
                ? 'This module has been saved to your draft and public versions of your Resource Center. Any edits made to the guide list will affect each version of the Resource Center which contains a reference to this module (including added or removed guides).'
                : 'This module has been saved to the draft version of your Resource Center. To make your changes public please publish your updates.';
        },
        shouldShowEditButton () {
            return get(this, 'module.state') !== 'disabled';
        },
        currentChildGuides () {
            let templateBlock;
            let templateChildren;
            const rcModuleId = get(this, 'module.attributes.resourceCenter.moduleId');

            if (rcModuleId === moduleIds.guideList) {
                const buildingBlocks = parsedBuildingBlockString(this.module.steps[0].buildingBlocks);
                templateBlock = findModuleGuideListBuildingBlock(buildingBlocks);
                templateChildren = templateBlock ? templateBlock.templateChildren : [];
            }

            return this.getChildGuides({ parentGuide: this.module }).map((guide) =>
                this.createDisplayGuide(guide, templateChildren)
            );
        },
        isAnnouncementsModule () {
            return get(this.module, 'attributes.resourceCenter.moduleId') === moduleIds.announcements;
        }
    },
    watch: {
        async module (newModule, oldModule) {
            if (this.getSegmentIdFromModule(newModule) !== this.getSegmentIdFromModule(oldModule)) {
                return;
            }

            await this.fetchGuides({ noCache: true });
            this.localModuleGuides = this.currentChildGuides;
        }
    },
    async created () {
        this.loading = true;
        await this.fetchGuides();
        this.localModuleGuides = this.currentChildGuides;
        this.previousGuides = cloneDeep(this.localModuleGuides);
        this.loading = false;
    },
    methods: {
        ...mapActions({
            fetchGuides: 'guides/fetch',
            saveModuleGuideList: 'resourceCenter/saveModuleGuideList',
            getResourceCenter: 'resourceCenter/get'
        }),
        showGuidesMultiselect () {
            this.isEditingGuidesList = true;
        },
        createDisplayGuide (guide, templateChildren) {
            const templateChild = find(templateChildren, { id: guide.id });
            const startDate = guide.showsAfter || guide.publishedAt;
            const { defaultList } = this.segmentsListByGroup;

            return {
                name: guide.name || 'Unnamed',
                segment: buildSegmentFromGuide(guide, this.segmentsList, defaultList),
                audience: guide.audience,
                audienceUiHint: guide.audienceUiHint,
                id: guide.id,
                showsAfter: startDate === 0 && !guide.showsAfter ? undefined : startDate,
                state: guide.state,
                launchMethod: launchMethodToString(guide.launchMethod),
                title: this.getGuideDisplayTitle(guide),
                keywords: get(templateChild, 'keywords', []),
                steps: guide.steps,
                subtitle: this.isOnboardingModule ? ONBOARDING_MODULE_SUBTITLE : ''
                // untranslatedLangs: this.getUntranslatedLangs(guide), // enable this functionality in APP-47713 for RC module localization widget work
            };
        },
        getGuideDisplayTitle (guide) {
            const guideConfig = this.localTemplateChildren.find((singleGuide) => singleGuide.id === guide.id);

            return guideConfig ? guideConfig.title : guide.name;
        },
        formatDropdownGuide (guide) {
            const formattedGuide = this.createDisplayGuide(guide);
            const alreadySelected = this.localModuleGuides.find((singleGuide) => singleGuide.id === formattedGuide.id);
            formattedGuide.checked = !!alreadySelected;

            return formattedGuide;
        },
        handleModuleChange (selectedGuides, property) {
            const compareGuide = this.previousGuides.map((guide) => guide[property]);
            const selectedGuideIds = selectedGuides.map((guide) => guide[property]);
            this.userHasChanges = !isEqual(selectedGuideIds, compareGuide);
            this.localModuleGuides = selectedGuides;
        },
        handleGuideOrderChange (selectedGuides) {
            this.handleModuleChange(selectedGuides, 'id');
        },
        handleModuleGuidesChange (selectedGuides) {
            this.handleModuleChange(selectedGuides, 'id');
        },
        handleModuleGuidesNameChange (selectedGuides) {
            this.handleModuleChange(selectedGuides, 'title');
        },
        toggleModal (modalName = null) {
            this.activeModal = modalName;
        },
        onModalConfirm (methodName) {
            this[methodName]();
        },
        async confirmSave () {
            const { moduleId, originId } = this.route.params;
            this.userHasChanges = false;
            this.isEditingGuidesList = false;
            this.toggleModal();
            await this.saveModuleGuideList({
                homeViewId: originId,
                moduleId,
                guideList: this.localModuleGuides
            });

            if (this.errorOnSave) {
                this.localModuleGuides = this.currentChildGuides;
                PendoNotification({
                    type: 'error',
                    title: 'Something went wrong!',
                    message: 'Action not available. Please try again later.',
                    duration: 5000
                });

                return;
            }
            this.previousGuides = cloneDeep(this.localModuleGuides);
            this.$emit('on-save');
        },
        onModalCancel () {
            if (!this.userHasChanges) {
                this.onModalConfirm(MODALS.DO_NOT_SAVE);

                return;
            }

            this.toggleModal(MODALS.CONFIRM_CANCEL);
        },
        confirmCancel () {
            this.localModuleGuides = this.currentChildGuides;
            this.toggleModal();
        },
        doNotSave () {
            this.localModuleGuides = cloneDeep(this.previousGuides);
            this.userHasChanges = false;
            this.isEditingGuidesList = false;
            this.toggleModal();
        },
        getSegmentIdFromModule (module) {
            if (!module.audience) return null;

            const { segment } = module.audience.find(({ segment } = {}) => segment !== undefined) || {};

            return segment.id;
        }
    }
};
</script>

<style scoped lang="scss">
.loading-container {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.no-guides-msg-wrapper {
    display: flex;
    flex-direction: column;

    .no-guides-msg {
        font-weight: 300;
        line-height: 1.4;
        margin: 0 24px;
        padding: 150px 0px;
        text-align: center;

        .header {
            font-size: 24px;
        }

        .body {
            color: $gray-lighter-2;
            font-size: 14px;
        }
    }
}

.select-guide-container {
    padding: 20px;
}

.bottom-line {
    border-bottom: 1px solid $gray-lighter-5;
}
</style>
